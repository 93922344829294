<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/hierarchy">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/hierarchy"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="hierquias-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="field field-checkbox">
                            <label for="ativo" class="mr-2 ml-0">Ativo</label>
                            <InputSwitch id="ativo" v-model="form.ativo" />
                        </div>
                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_id">Cliente</label>
                            <Dropdown
                                id="customer_id"
                                :filter="true"
                                showClear
                                @filter="loadingCustomers"
                                @change="onCustomerChange($event)"
                                v-model="form.customerId"
                                :options="customers"
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Escolha"
                                :loading="loading"
                                :class="{ 'p-invalid': submitted && !form.customerId }"
                            ></Dropdown>
                            <small class="p-error" v-if="submitted && !form.customerId">Cliente é obrigatório.</small>
                        </div>
                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_branch">Unidade</label>
                            <Dropdown
                                id="customer_branch"
                                showClear
                                :filter="true"
                                @filter="loadingBranches"
                                @change="onChangeFindHierarchy($event)"
                                v-model="form.customerBranchId"
                                :options="branches"
                                optionLabel="name"
                                optionValue="id"
                                :loading="loadingBranches"
                                placeholder="Escolha uma unidade"
                                :class="{ 'p-invalid': submitted && !form.customerBranchId }"
                            >
                            </Dropdown>
                            <small class="p-error" v-if="submitted && !form.customerBranchId">Unidade é obrigatório.</small>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="customer_department">Setor</label>
                                <Dropdown
                                    id="customer_department"
                                    showClear
                                    :filter="true"
                                    @filter="loadingDepartments"
                                    @change="onChangeFindHierarchy($event)"
                                    v-model="form.customerDepartmentId"
                                    :options="departments"
                                    optionLabel="name"
                                    optionValue="id"
                                    :loading="loadingDepartments"
                                    placeholder="Escolha um"
                                    :class="{ 'p-invalid': submitted && !form.customerDepartmentId }"
                                ></Dropdown>
                                <small class="p-error" v-if="submitted && !form.customerDepartmentId">Setor é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="customer_position">Cargo</label>
                                <Dropdown
                                    id="customer_position"
                                    showClear
                                    :filter="true"
                                    @filter="loadingPositions"
                                    @change="onChangeFindHierarchy($event)"
                                    v-model="form.customerPositionId"
                                    :options="positions"
                                    optionLabel="name"
                                    optionValue="id"
                                    :loading="loadingPositions"
                                    placeholder="Escolha um"
                                    :class="{ 'p-invalid': submitted && !form.customerPositionId }"
                                ></Dropdown>
                                <small class="p-error" v-if="submitted && !form.customerPositionId">Cargo é obrigatório.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="description_department">Descrição setor</label>
                                <Textarea v-model.trim="form.descriptionDepartment" autocomplete="off" :autoResize="true" rows="2" />
                            </div>
                            <div class="field col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="description_position">Descrição cargo</label>
                                <Textarea v-model.trim="form.description" autocomplete="off" :autoResize="true" rows="2" />
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            form: {
                ativo: true
            },
            loading: false,
            submitted: false,
            loadingCustomers: true,
            loadingBranches: false,
            loadingDepartments: false,
            loadingPositions: false,
            existe: false,
            branches: [],
            departments: [],
            positions: [],
            customers: [],
            filter: []
        };
    },
    created() {
        this.service = new BaseService('/customer/hierarchies');
        this.$serviceCustomer = new BaseService('/customers/combo');
    },
    async mounted() {
        this.$service = new BaseService('/customer/hierarchies');
        this.$serviceHierarchy = new BaseService('/customer/hierarchies/has-hierarchy');
        this.$serviceCustomer = new BaseService('customers/combo');
        this.$serviceBranches = new BaseService('/customer/branches/combo');
        this.$serviceDepartment = new BaseService('/customer/departments/combo');
        this.$servicePositions = new BaseService('/customer/positions/combo');
        await this.loadCustomers();
        if (this.$route.params.id) {
            const { data } = await this.$service.findById(this.$route.params.id);
            this.form = data;
            let cliente = data.cliente;
            this.form.idCliente = cliente != null ? cliente.id : null;
            this.loadingBranches = true;
            this.loadingDepartments = true;
            this.loadingPositions = true;
            let customerId = this.form.customerId;
            await this.load(customerId);
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar hierarquia' : 'Adicionar hierarquia';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            /*  if (!this.form.descricao) return true; */
        },
        async loadCustomers() {
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
            this.loadingCustomers = false;
        },
        async onChangeFindHierarchy() {
            this.loadingPositions = true;
            if (this.form.customerId && this.form.customerBranchId && this.form.customerDepartmentId && this.form.customerPositionId) {
                const { data } = await this.$serviceHierarchy.findAll({
                    customerId: this.form.customerId,
                    customerBranchId: this.form.customerBranchId,
                    customerDepartmentId: this.form.customerDepartmentId,
                    customerPositionId: this.form.customerPositionId
                });
                const hasHierarchy = data?.id > 0;
                this.existe = hasHierarchy;
                if (this.existe) this.$toast.add({ severity: 'error', summary: 'Hierarquia já cadastrada!', life: 3000 });
            }
            this.loadingPositions = false;
        },
        setEmployeeHierarchy(customerHierarchyId) {
            this.form.id = customerHierarchyId;
        },
        async onCustomerChange(event) {
            this.loading = true;
            this.load(event.value);
        },
        async load(id) {
            const [branches, departments, positions] = await Promise.all([
                this.$serviceBranches.findAll({ customerId: id }),
                this.$serviceDepartment.findAll({ customerId: id }),
                this.$servicePositions.findAll({ customerId: id })
            ]);
            this.branches = branches.data;
            this.departments = departments.data;
            this.positions = positions.data;
            this.loadingBranches = false;
            this.loadingDepartments = false;
            this.loadingPositions = false;
            this.loading = false;
        }
    },
    components: { AppInfoManual }
};
</script>
